<template>
  <el-form :model="wuzihuikudiaopeiForm" :rules="rules" ref="form" label-width="100px" size="small">
    <el-form-item label="回库仓库" prop="cangku">
      <el-select v-model="wuzihuikudiaopeiForm.cangku" placeholder="请选择所属仓库">
        <el-option v-for="item in cangkus_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="材料名称" prop="cailiao">
      <el-select v-model="wuzihuikudiaopeiForm.cailiao" placeholder="请选择材料名称">
        <el-option v-for="item in cailiaos_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="材料规格" prop="cailiaoguige">
      <el-select v-model="wuzihuikudiaopeiForm.cailiaoguige" placeholder="请选择材料规格">
        <el-option v-for="item in cailiaoguiges_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="回库数量" prop="amount">
      <el-input v-model.number="wuzihuikudiaopeiForm.amount"></el-input>
    </el-form-item>
    <el-form-item label="回库时间" prop="date">
      <el-date-picker
        type="date"
        :picker-options="pickerOptions"
        value-format="yyyy-MM-dd"
        placeholder="选择日期"
        v-model="wuzihuikudiaopeiForm.date"
        style="width: 100%;"
      >
      </el-date-picker>
    </el-form-item>

    <el-form-item label="回库备注" prop="remark">
      <el-input type="textarea" v-model="wuzihuikudiaopeiForm.remark"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    wuzihuikudiaopeiForm: Object
  },
  data() {
    return {
      isValidationOk: false,
      cangkus_options: [],
      cailiaos_options: [],
      cailiaoguiges_options: [],

      pickerOptions: {
        // 限制只能选择今天之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      rules: {
        cangku: [{ required: true, message: '请选择所属仓库', trigger: 'blur' }],
        cailiao: [{ required: true, message: '请选择材料名称', trigger: 'blur' }],
        cailiaoguige: [{ required: true, message: '请选择材料规格', trigger: 'blur' }],
        amount: [{ required: true, message: '请输入回库数量', trigger: 'blur' }, { type: 'number', message: '数量必须为数字' }],
        date: [{ required: true, message: '请选择回库时间', trigger: 'blur' }]
      }
    }
  },
  methods: {
    getCangkus() {
      this.$axios.get('/getCangkus/').then(res => {
        console.log('getCangkus list:', res.data)
        res.data.forEach(element => {
          let cangku = {}
          cangku.label = String(element.name)
          cangku.value = element.id
          this.cangkus_options.push(cangku)
        })
        console.log('cangkus_options', this.cangkus_options)
      })
    },
    getCailiaos() {
      this.$axios.get('/getCailiaos/').then(res => {
        console.log('getCailiaos list:', res.data)
        res.data.forEach(element => {
          let cailiao = {}
          cailiao.label = String(element.name)
          cailiao.value = element.id
          this.cailiaos_options.push(cailiao)
        })
        console.log('cailiaos_options', this.cailiaos_options)
      })
    },
    getCailiaoguiges() {
      this.$axios.get('/getCailiaoguiges/').then(res => {
        console.log('getCailiaoguiges list:', res.data)
        res.data.forEach(element => {
          let cailiaoguige = {}
          cailiaoguige.label = String(element.name)
          cailiaoguige.value = element.id
          this.cailiaoguiges_options.push(cailiaoguige)
        })
        console.log('cailiaoguiges_options', this.cailiaoguiges_options)
      })
    },
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log('validation ok!')
          this.isValidationOk = true
        } else {
          console.log('Validation error!')
          this.isValidationOk = false
          return false
        }
      })
    },
    resetForm() {
      this.$refs['from'].resetFields()
    }
  },
  created() {
    this.getCangkus(), this.getCailiaos(), this.getCailiaoguiges()
  }
}
</script>

<style lang="scss" scoped></style>
